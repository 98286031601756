define('ember-mu-registration/mixins/mu-change-password', ['exports', 'ember', 'ember-mu-registration/configuration'], function (exports, _ember, _emberMuRegistrationConfiguration) {
  exports['default'] = _ember['default'].Mixin.create({
    basePath: _ember['default'].computed(function () {
      return _emberMuRegistrationConfiguration['default'].accountBasePath;
    }),

    _init: function _init() {
      this.setProperties({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
      });
    },

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        this.set('errorMessage', '');

        var properties = this.getProperties('oldPassword', 'newPassword', 'newPasswordConfirmation');

        _ember['default'].$.ajax({
          url: this.get('basePath') + '/current/changePassword',
          type: 'PATCH',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/vnd.api+json'
          },
          data: JSON.stringify({
            data: {
              type: 'accounts',
              id: 'current',
              attributes: {
                'old-password': properties['oldPassword'],
                'new-password': properties['newPassword'],
                'new-password-confirmation': properties['newPasswordConfirmation']
              }
            }
          })
        }).then(function (response) {
          _this._init();
        }, function (reason) {
          var error = reason.responseJSON.errors[0].title;
          console.log('Password change failed: ' + error);
          _this.set('errorMessage', error);
        });
      }
    }
  });
});