define('ember-mu-login/configuration', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULTS = {
    sessionBasePath: '/sessions'
  };

  /**
    Ember Mu Login's configuration object.
  
    To change any of these values, set them on the application's environment
    object, e.g.:
  
    ```js
    // config/environment.js
    ENV['ember-mu-login'] = {
      sessionBasePath: '/sessions'
    };
    ```
  
    @class Configuration
    @extends Object
    @module ember-mu-login/configuration
    @public
  */
  exports['default'] = {

    /**
      Base path for the login and logout requests
       @property sessionBasePath
      @readOnly
      @static
      @type String
      @default '/sessions'
      @public
    */
    sessionBasePath: DEFAULTS.sessionBasePath,

    load: function load(config) {
      var wrappedConfig = _ember['default'].Object.create(config);
      for (var property in this) {
        if (this.hasOwnProperty(property) && _ember['default'].typeOf(this[property]) !== 'function') {
          this[property] = wrappedConfig.getWithDefault(property, DEFAULTS[property]);
        }
      }
    }
  };
});