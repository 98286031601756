define('ember-cli-swiper/components/swiper-slide', ['exports', 'ember', 'ember-cli-swiper/templates/components/swiper-slide'], function (exports, _ember, _emberCliSwiperTemplatesComponentsSwiperSlide) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    layout: _emberCliSwiperTemplatesComponentsSwiperSlide['default'],
    classNameBindings: ['mergedClasses'],

    mergedClasses: computed('class', function () {
      return this.get('class') ? this.get('class') + ' swiper-slide' : 'swiper-slide';
    })

  });
});