define('ember-mu-login/mixins/mu-login', ['exports', 'ember', 'ember-ajax/errors'], function (exports, _ember, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Mixin.create({
    session: _ember['default'].inject.service('session'),
    actions: {
      login: function login() {
        var _this = this;

        this.set('loading', true);
        this.set('errorMessage', '');
        var credentials = this.getProperties('nickname', 'password');
        this.get('session').authenticate('authenticator:mu-semtech', credentials).then(function () {
          _this.set('loading', false);
        })['catch'](function (reason) {
          _this.set('loading', false);
          if (reason.errors[0].status === '0') {
            _this.set('errorMessage', 'Failed to connect to server');
          } else if ((0, _emberAjaxErrors.isBadRequestError)(reason)) {
            var error = reason.errors[0].title;
            _this.set('errorMessage', error);
          } else {
            _this.set('errorMessage', 'Something went wrong, please try again later');
          }
        });
      }
    }
  });
});