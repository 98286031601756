define('ember-mu-registration/configuration', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULTS = {
    accountBasePath: '/accounts'
  };

  /**
    Ember Mu Registration's configuration object.
  
    To change any of these values, set them on the application's environment
    object, e.g.:
  
    ```js
    // config/environment.js
    ENV['ember-mu-registration'] = {
      accountBasePath: '/accounts'
    };
    ```
  
    @class Configuration
    @extends Object
    @module ember-mu-registration/configuration
    @public
  */
  exports['default'] = {

    /**
      Base path for the registration requests
       @property accountBasePath
      @readOnly
      @static
      @type String
      @default '/accounts'
      @public
    */
    accountBasePath: DEFAULTS.accountBasePath,

    load: function load(config) {
      var wrappedConfig = _ember['default'].Object.create(config);
      for (var property in this) {
        if (this.hasOwnProperty(property) && _ember['default'].typeOf(this[property]) !== 'function') {
          this[property] = wrappedConfig.getWithDefault(property, DEFAULTS[property]);
        }
      }
    }
  };
});