define('ember-mu-login/authenticators/mu-semtech', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'ember-mu-login/configuration'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _emberMuLoginConfiguration) {
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    ajax: _ember['default'].inject.service(),

    basePath: _ember['default'].computed(function () {
      return _emberMuLoginConfiguration['default'].sessionBasePath;
    }),

    restore: function restore(data) {
      return this.get('ajax').request(this.get('basePath') + "/current", {
        type: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      }).then(function () {
        return data;
      });
    },

    authenticate: function authenticate(options) {
      return this.get('ajax').request(this.get('basePath'), {
        type: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/vnd.api+json'
        },
        data: JSON.stringify({
          data: {
            type: 'sessions',
            attributes: {
              nickname: options['nickname'],
              password: options['password']
            }
          }
        })
      });
    },

    invalidate: function invalidate() {
      return this.get('ajax').request(this.get('basePath') + '/current', {
        type: 'DELETE',
        headers: {
          'Content-Type': 'application/vnd.api+json'
        }
      });
    }
  });
});