define('ember-mu-registration/mixins/mu-register', ['exports', 'ember', 'ember-mu-registration/configuration'], function (exports, _ember, _emberMuRegistrationConfiguration) {
  exports['default'] = _ember['default'].Mixin.create({
    basePath: _ember['default'].computed(function () {
      return _emberMuRegistrationConfiguration['default'].accountBasePath;
    }),

    _init: function _init() {
      this.set('loading', false);
      this.setProperties({
        name: '',
        nickname: '',
        password: '',
        passwordConfirmation: ''
      });
    },

    actions: {
      register: function register() {
        var _this = this;

        this.set('loading', true);
        this.set('errorMessage', '');

        var properties = this.getProperties('name', 'nickname', 'password', 'passwordConfirmation');

        _ember['default'].$.ajax({
          url: this.get('basePath'),
          type: 'POST',
          dataType: 'json',
          headers: {
            'Content-Type': 'application/vnd.api+json'
          },
          data: JSON.stringify({
            data: {
              type: 'accounts',
              attributes: {
                name: properties['name'],
                nickname: properties['nickname'],
                password: properties['password'],
                'password-confirmation': properties['passwordConfirmation']
              }
            }
          })
        }).then(function (response) {
          _this._init();
        }, function (reason) {
          _this.set('loading', false);
          var error = reason.responseJSON.errors[0].title;
          console.log('Registration failed: ' + error);
          _this.set('errorMessage', error);
        });
      }
    }
  });
});