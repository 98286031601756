define('ember-mu-registration/mixins/mu-unregister', ['exports', 'ember', 'ember-mu-registration/configuration'], function (exports, _ember, _emberMuRegistrationConfiguration) {
  exports['default'] = _ember['default'].Mixin.create({
    basePath: _ember['default'].computed(function () {
      return _emberMuRegistrationConfiguration['default'].accountBasePath;
    }),

    actions: {
      unregister: function unregister() {
        _ember['default'].$.ajax({
          url: this.get('basePath') + '/current',
          type: 'DELETE',
          headers: {
            'Content-Type': 'application/vnd.api+json'
          }
        });
      }
    }
  });
});